/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/litepicker@2.0.12/dist/litepicker.js
 * - /npm/tableexport.jquery.plugin@1.26.0/libs/FileSaver/FileSaver.min.js
 * - /npm/tableexport.jquery.plugin@1.26.0/libs/js-xlsx/xlsx.core.min.js
 * - /npm/bootstrap-table@1.21.4/dist/bootstrap-table.js
 * - /npm/bootstrap-table@1.21.4/dist/locale/bootstrap-table-nl-NL.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
